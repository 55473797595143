var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "sticky",
      "top": "0",
      "z-index": "20"
    }
  }, [_c('div', {
    staticClass: "position-absolute setting-icon-position",
    on: {
      "click": function click($event) {
        _vm.showConfig = !_vm.showConfig;
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer text-danger",
    attrs: {
      "id": "reservation-ticket-rotation-settings-icon",
      "size": "24",
      "icon": "SettingsIcon"
    }
  }), _c('BTooltip', {
    attrs: {
      "target": "reservation-ticket-rotation-settings-icon",
      "placement": "left",
      "title": _vm.$t('ticket.showHideConfig')
    }
  })], 1), _c('b-card', {
    class: 'border config-container ' + (_vm.showConfig ? 'show' : 'hide'),
    attrs: {
      "no-body": "",
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('ticket.sectionsTitle.config')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "py-1 px-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column gap-2 mb-50"
  }, [_c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showTimeHold,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showTimeHold", $$v);
      },
      expression: "config.showTimeHold"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.holdTime')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showStatus", $$v);
      },
      expression: "config.showStatus"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketStatus')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showBookingClass,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showBookingClass", $$v);
      },
      expression: "config.showBookingClass"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.bookingClass')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.useQuillEditor,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "useQuillEditor", $$v);
      },
      expression: "config.useQuillEditor"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.useEditor')) + " ")])], 1)]), _c('b-col', {
    staticClass: "d-flex flex-column gap-2 mb-50"
  }, [_c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showPayment,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showPayment", $$v);
      },
      expression: "config.showPayment"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paymentInfo')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showPrice", $$v);
      },
      expression: "config.showPrice"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.price')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showPriceNetFare,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showPriceNetFare", $$v);
      },
      expression: "config.showPriceNetFare"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceNetFare')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showPriceDetail,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showPriceDetail", $$v);
      },
      expression: "config.showPriceDetail"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceDetail')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showAncillaries,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showAncillaries", $$v);
      },
      expression: "config.showAncillaries"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.showAncillaries')) + " ")])], 1)]), _c('b-col', {
    staticClass: "d-flex flex-column align-items-start gap-2"
  }, [_c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showAgency,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showAgency", $$v);
      },
      expression: "config.showAgency"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.agencyInfo')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showAgencyLogo,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showAgencyLogo", $$v);
      },
      expression: "config.showAgencyLogo"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Logo AG ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showBackground,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showBackground", $$v);
      },
      expression: "config.showBackground"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.showBackground')) + " ")])], 1), _c('label', {
    staticClass: "d-flex align-items-center cursor-pointer mb-0 fit-content"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-25",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.config.showBackgroundColor,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "showBackgroundColor", $$v);
      },
      expression: "config.showBackgroundColor"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.showBackgroundColor')) + " ")])], 1)])], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('BFormGroup', {
    attrs: {
      "label-for": "bankCode"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t('ticket.bankAccount')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "label": "bankAccountNo",
      "options": _vm.bankAccountsList,
      "filterable": false,
      "searchable": "",
      "loading": _vm.loading,
      "placeholder": _vm.$t('ticket.phBankAccount'),
      "clearable": false
    },
    on: {
      "open": _vm.fetchBankAccountsList
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start"
        }, [_c('BImg', {
          attrs: {
            "src": _vm.resolveBankCode(data.bankCode).bankLogo,
            "width": "50"
          }
        }), _c('div', [_vm._v(_vm._s(_vm.resolveBankCode(data.bankCode).bankName) + " - "), _c('b', [_vm._v(_vm._s(data.bankAccountNo))]), _vm._v(" - " + _vm._s(data.bankAccountName))])], 1)];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start"
        }, [_c('BImg', {
          attrs: {
            "src": _vm.resolveBankCode(data.bankCode).bankLogo,
            "width": "50"
          }
        }), _c('div', [_vm._v(_vm._s(_vm.resolveBankCode(data.bankCode).bankName) + " - "), _c('b', [_vm._v(_vm._s(data.bankAccountNo))]), _vm._v(" - " + _vm._s(data.bankAccountName))])], 1)];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref) {
        var loading = _ref.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.config.bankAccount,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "bankAccount", $$v);
      },
      expression: "config.bankAccount"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('BFormGroup', {
    attrs: {
      "label-for": "themeColor"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t('ticket.language')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "options": _vm.TICKET_LANGUAGE_OPTIONS,
      "reduce": function reduce(val) {
        return val.value;
      },
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }]),
    model: {
      value: _vm.config.locale,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "locale", $$v);
      },
      expression: "config.locale"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('BFormGroup', {
    attrs: {
      "label-for": "themeColor"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t('ticket.color')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    attrs: {
      "options": _vm.TICKET_COLOR_OPTIONS,
      "reduce": function reduce(val) {
        return val.value.toUpperCase();
      },
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
      }
    }]),
    model: {
      value: _vm.config.color,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "color", $$v);
      },
      expression: "config.color"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }